import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  sideMenuCollapseClicked,
  menuClicked,
} from '../../state/reducers/menuSlice';
import arrow from '../../static/svg/double-arrows.svg';
import './style.css';

function CollapseButton() {
  const dispatch = useDispatch();
  const [arrowClass, setArrowClass] = useState('collapse-arrow right');

  const handleClick = () => {
    dispatch(sideMenuCollapseClicked());
    dispatch(menuClicked());
    if (arrowClass === 'collapse-arrow left') {
      setArrowClass('collapse-arrow right');
    } else {
      setArrowClass('collapse-arrow left');
    }
  };

  return (
    <div className="collapse-container" onClick={handleClick}>
      <img src={arrow} className={arrowClass} alt="treeview arrow" />
    </div>
  );
}

export default CollapseButton;
