import './style.css';

function Checkbox({ onChange, item, checked }) {
  return (
    <>
      <label className="checkbox-container">
        {item}
        <input type="checkbox" onChange={onChange} checked={checked} />
        <span className="checkbox-checkmark"></span>
      </label>
    </>
  );
}

export default Checkbox;
