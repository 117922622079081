import React from 'react';
import { ReactComponent as LoadingAnim } from '../../static/svg/loader.svg';
import './style.css';

function Loader() {
  return (
    <div className="loader-background">
      <LoadingAnim />
    </div>
  );
}

export default Loader;
