import { useSelector } from 'react-redux';
import {
  Bar,
  Cell,
  Line,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';

import {
  formatDateToShortMonth,
  formatDateToQuarter,
  getQuarterIndex,
} from '../../utils/formatters';

import CustomTooltip from '../CustomTooltip';
import Tile from '../Tile';

import * as constants from './constants';
import './style.css';

function DashboardChart({ highlightedMetric, metadata, viewId, data }) {
  const { budget, selectedDate, selectedPeriod } = useSelector(
    state => state.dashboard
  );

  let monthIndex = new Date(selectedDate).getMonth();
  if (selectedPeriod === 'mulyears') {
    monthIndex = getQuarterIndex(monthIndex);
  }

  let metricsHiglighted = [];
  // if (highlightedMetric !== null) {
  //   const metric = metadata[highlightedMetric].label;
  //   const budgetIndex = metadata.findIndex(
  //     item => item.label === `${metric} Budget`
  //   );

  //   if (budget) {
  //     metricsHiglighted = [highlightedMetric, budgetIndex];
  //   } else {
  //     metricsHiglighted = [highlightedMetric];
  //   }
  // }

  /**
   * Checks if the given metric is in highlighted state
   * @param {int} index - index of the chart
   * @returns {bool} - true if metric is highlighted, false otherwise
   */
  const isMetricHighlighted = index => {
    return highlightedMetric == index || highlightedMetric == null;
  };

  /**
   * Returns the radius of the bar
   * @param {int} index - index of the chart
   * @returns {array} - array with the radii for each corner: [top-left, top-right, bottom-right, bottom-left]
   */
  const isRoundedBar = (index, budget) => {
    // for stacked bars the last bar should have rounded corners
    const barChartIndices = metadata
      .map((chart, index) => {
        if (
          (chart.type === 'bar' &&
            !budget &&
            !chart.label.includes('Budget')) ||
          (chart.type === 'bar' && budget && chart.label.includes('Budget'))
        ) {
          return index;
        }
      })
      .filter(index => index != null);

    const roundedBarIndices = [highlightedMetric, barChartIndices.at(-1)];

    return (
      (viewId != 3 && roundedBarIndices.includes(index)) || // rounds highlighted metric and last bar
      (viewId != 3 &&
      barChartIndices.includes(budget ? 7 : 0) &&
      index == budget
        ? 7
        : 0) || // rounds first bar for stacked bar chart
      (viewId == 3 && index == metadata.length - 1) // rounds last bar for stacked bar chart
    );
  };

  return (
    <Tile className="tile-content-fn-chart">
      <div style={{ height: '100%', padding: '20px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={data} {...constants.COMPOSED_CHART_PARAMETERS}>
            {/* X,Y axes and reference line  */}
            <XAxis
              xAxisId={0}
              padding={{ left: 10 }}
              {...constants.X_AXIS_PARAMETERS}
              tickFormatter={
                selectedPeriod === 'mulyears'
                  ? formatDateToQuarter
                  : formatDateToShortMonth
              }
            />
            <XAxis
              xAxisId={1}
              padding={{ left: 0 }}
              hide={true}
              {...constants.X_AXIS_PARAMETERS}
              tickFormatter={
                selectedPeriod === 'mulyears'
                  ? formatDateToQuarter
                  : formatDateToShortMonth
              }
            />
            <YAxis
              {...constants.Y_AXIS_PARAMETERS}
              domain={
                viewId === 2
                  ? ([dataMin, dataMax]) => {
                      if (dataMax < 0) {
                        return [dataMin * 1.25, 0];
                      } else if (dataMin > 0) {
                        return [0, dataMax * 1.25];
                      } else {
                        return [dataMin * 1.1, dataMax * 1.1];
                      }
                    }
                  : [0, 'auto']
              }
            />
            <ReferenceLine {...constants.REFERENCE_LINE_PARAMETERS} />
            {/* Graphs + Tooltips */}
            <Tooltip
              content={<CustomTooltip period={selectedPeriod} />}
              {...constants.TOOLTIP_PARAMETERS}
            />

            {metadata.map((chart, index) => {
              return (
                chart.type === 'bar' && (
                  <>
                    {!chart.label.includes('Budget') ? (
                      <Bar
                        dataKey={chart.label}
                        fill={chart.color} // necessary to show correct color in tooltip
                        opacity={
                          metricsHiglighted.includes(index) ||
                          metricsHiglighted.length === 0
                            ? 1
                            : 0.1
                        }
                        radius={
                          isRoundedBar(index, false)
                            ? [10, 10, 0, 0]
                            : [0, 0, 0, 0]
                        }
                        key={index}
                        stackId={'a'}
                        barSize={budget ? 13 : 20}
                      >
                        {(selectedPeriod === 'rf' ||
                          selectedPeriod === 'mulyears') &&
                          data.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={
                                index > monthIndex
                                  ? chart.color.substring(
                                      0,
                                      chart.color.length - 2
                                    ) + '0.5)'
                                  : chart.color
                              }
                            />
                          ))}
                      </Bar>
                    ) : (
                      <>
                        {budget && (
                          <Bar
                            dataKey={chart.label}
                            fill={chart.color} // necessary to show correct color in tooltip
                            opacity={
                              metricsHiglighted.includes(index) ||
                              metricsHiglighted.length === 0
                                ? 1
                                : 0.1
                            }
                            radius={
                              isRoundedBar(index, true)
                                ? [10, 10, 0, 0]
                                : [0, 0, 0, 0]
                            }
                            key={index}
                            stackId={'b'}
                            barSize={5}
                          />
                        )}
                      </>
                    )}
                  </>
                )
              );
            })}
            {metadata.map((chart, index) => {
              return (
                chart.type === 'line' && (
                  <>
                    {!chart.label.includes('Budget') ? (
                      <>
                        {(selectedPeriod === 'rf' ||
                          selectedPeriod === 'mulyears') && (
                          <defs>
                            <linearGradient
                              id={`colorUv${index}`}
                              x1="0%"
                              y1="0"
                              x2="100%"
                              y2="0"
                            >
                              <stop offset="0%" stopColor={chart.color} />
                              <stop
                                offset={`${((monthIndex + 1) / 12) * 100 - 3}%`}
                                stopColor={chart.color}
                              />
                              <stop
                                offset={`${((monthIndex + 1) / 12) * 100 - 3}%`}
                                stopColor={
                                  chart.color.substring(
                                    0,
                                    chart.color.length - 2
                                  ) + '0.4)'
                                }
                              />
                              <stop
                                offset={`${100}%`}
                                stopColor={
                                  chart.color.substring(
                                    0,
                                    chart.color.length - 2
                                  ) + '0.4)'
                                }
                              />
                            </linearGradient>
                          </defs>
                        )}
                        <Line
                          type="monotone"
                          dataKey={chart.label}
                          stroke={
                            selectedPeriod === 'rf' ||
                            selectedPeriod === 'mulyears'
                              ? `url(#colorUv${index})`
                              : chart.color
                          }
                          strokeOpacity={
                            metricsHiglighted.includes(index) ||
                            metricsHiglighted.length === 0
                              ? 1
                              : 0.1
                          }
                          activeDot={isMetricHighlighted(index)}
                          key={index}
                          {...constants.LINE_PARAMETERS}
                          xAxisId={budget ? 1 : 0}
                        />
                      </>
                    ) : (
                      <>
                        {budget && (
                          <Line
                            type="monotone"
                            dataKey={chart.label}
                            stroke={chart.color}
                            strokeOpacity={
                              metricsHiglighted.includes(index) ||
                              metricsHiglighted.length === 0
                                ? 1
                                : 0.1
                            }
                            activeDot={isMetricHighlighted(index)}
                            key={index}
                            strokeDasharray="6 6"
                            {...constants.LINE_PARAMETERS_BUDGET}
                            xAxisId={1}
                          />
                        )}
                      </>
                    )}
                  </>
                )
              );
            })}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </Tile>
  );
}

export default DashboardChart;
