import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { menuClicked } from '../../state/reducers/menuSlice';
// import Arrow from '../Arrow';

import './style.css';

function NavMenuDropdown({ children, icon, title, background, color }) {
  const dispatch = useDispatch();
  const { clientsMenu, dashboardsMenu, sideMenuCollapsed } = useSelector(
    state => state.menu
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (title === 'Clients') {
      setOpen(clientsMenu);
    }

    if (title === 'Dashboards') {
      setOpen(dashboardsMenu);
    }
  }, [clientsMenu, dashboardsMenu]);

  const contentEl = useRef();
  const handleToggle = () => {
    if (title === 'Clients') {
      dispatch(menuClicked('clientsMenu'));
      setOpen(clientsMenu);
    }

    if (title === 'Dashboards') {
      dispatch(menuClicked('dashboardsMenu'));
      setOpen(dashboardsMenu);
    }
  };

  return (
    <>
      <ul className={`nav-menu-dropdown nav-menu-${background}`}>
        <div
          className={`nav-menu-dropdown-label nav-menu-dropdown-label-${color}`}
        >
          <span></span>
          {icon && (
            <span
              className="nav-menu-dropdown-label-icon"
              // onClick={handleToggle}
            >
              <img src={icon} />
            </span>
          )}
          <span
            className="nav-menu-title"
            style={{
              opacity: sideMenuCollapsed ? '0' : '1',
              transition: 'opacity 0.7s ease-in-out',
            }}
          >
            {title}
          </span>
          {/* <span
            onClick={handleToggle}
            className="nav-menu-dropdown-label-arrow"
            style={{
              right: sideMenuCollapsed ? '205px' : '16px',
              transition: 'all 0.7s ease-in-out',
            }}
          >
            <Arrow
              shape={
                color === 'purplenavy' ? 'arrowhead' : 'arrowhead_turquoise'
              }
              direction={
                sideMenuCollapsed
                  ? open
                    ? 'left'
                    : 'right'
                  : open
                  ? 'up'
                  : 'down'
              }
            />
          </span> */}
        </div>
        {sideMenuCollapsed ? (
          <>
            {open && (
              <div
                className={
                  title === 'Dashboards'
                    ? 'nav-menu-collapsed-show-options-clients'
                    : 'nav-menu-collapsed-show-options'
                }
              >
                {children}
              </div>
            )}
          </>
        ) : (
          <div
            ref={contentEl}
            className="nav-menu-dropdown-transition"
            style={{
              maxHeight:
                open && contentEl.current
                  ? contentEl.current.scrollHeight
                  : '0rem',
            }}
          >
            {children}
          </div>
        )}
      </ul>
    </>
  );
}

export default NavMenuDropdown;
