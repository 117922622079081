import { useDispatch, useSelector } from 'react-redux';
import { viewSwitched, getData } from '../../state/reducers/dashboardSlice';
import Button from '../Button';
import Tile from '../Tile';
import GraphIcon from '../Icons/GraphIcon';
import TableIcon from '../Icons/TableIcon';

import './style.css';

function ViewToggle({ show }) {
  const dispatch = useDispatch();
  const { selectedView } = useSelector(state => state.dashboard);

  const toggle = () => {
    if (selectedView === 'table') {
      dispatch(viewSwitched('graphs'));
      dispatch(getData());
    } else {
      dispatch(viewSwitched('table'));
      dispatch(getData());
    }
  };

  return (
    <div
      className="view-toggle-container"
      style={{ display: show ? 'block' : 'none' }}
    >
      <Tile className="tile-content-view-fn">
        <Button className="view-toggle-button" onClick={toggle}>
          <GraphIcon
            fill={
              selectedView === 'graphs' ? '#545454' : 'rgba(84, 84, 84, 0.20)'
            }
          />
          <span
            className="view-toggle-span"
            style={{
              color:
                selectedView === 'graphs'
                  ? '#545454'
                  : 'rgba(84, 84, 84, 0.20)',
            }}
          >
            Graph
          </span>
          <div className="view-toggle-separator"></div>
          <TableIcon
            fill={
              selectedView === 'table' ? '#545454' : 'rgba(84, 84, 84, 0.20)'
            }
          />
          <span
            className="view-toggle-span"
            style={{
              color:
                selectedView === 'table' ? '#545454' : 'rgba(84, 84, 84, 0.20)',
            }}
          >
            Table
          </span>
        </Button>
      </Tile>
    </div>
  );
}

export default ViewToggle;
