import './style.css';

const ListItemColor = ({ color }) => {
  return <div className="list-item-color" style={{ backgroundColor: color }} />;
};

const ListItemLabel = ({ label }) => (
  <div className="list-item-label">{label}</div>
);

const ListItemAmount = ({ value }) => (
  <div className="list-item-number">{value}</div>
);

const ListItem = ({ color, label, value, className }) => {
  return (
    <div className={`list-item-wrapper ${className}`}>
      <ListItemColor color={color} />
      <ListItemLabel label={label} />
      <ListItemAmount value={value} />
    </div>
  );
};

export default ListItem;
