import { useDispatch, useSelector } from 'react-redux';
import ButtonBack from '../../components/Button/ButtonBack';
import ButtonDataUpdate from '../../components/Button/ButtonDataUpdate';
import Loader from '../../components/Loader';
import Nav from '../../components/Nav';
import Section from '../../components/Layout/Section';

import Selector from '../../components/Selector';

import { Header, Title, Main, Content } from '../../components/Layout/styles';

import ViewSelector from '../../components/ViewSelector';
import {
  getData,
  getBudgetScenarios,
  resetClicked,
  divisionSelected,
  dateChanged,
  periodChanged,
} from '../../state/reducers/dashboardSlice';

import ProfitAndLossDashboard from '../../components/ProfitAndLossDashboard/component';
import BalanceSheetDashboard from '../../components/BalanceSheetDashboard';

import { DASHBOARD_TITLES } from './constants';
import ViewToggle from '../../components/ViewToggle/component';

function Dashboard() {
  const dispatch = useDispatch();
  const {
    isLoading,
    viewId,
    selectedDivision,
    selectedDashboard,
    selectedView,
  } = useSelector(state => state.dashboard);
  const { selectedClient } = useSelector(state => state.menu);

  return (
    <div className="dashboard-container">
      {isLoading && <Loader />}

      <Nav
        onDivisionChange={division => {
          dispatch(divisionSelected(division));
          dispatch(getBudgetScenarios());
        }}
      />
      <Main>
        <Content>
          <Header>
            {/* Header (Left): Title and UpdateButton */}
            <Section.Primary>
              {/* <Title>{DASHBOARD_TITLES[selectedDashboard]}</Title> */}

              {/* <ButtonDataUpdate
                selectedClient={selectedClient}
                onClick={() => {
                  dispatch(resetClicked());
                  dispatch(getData());
                }}
              /> */}

              {/* <ViewSelector
                show={viewId === 1 && selectedDashboard === 'profit-and-loss'}
              /> */}
              <ViewToggle
                show={viewId === 1 && selectedDashboard === 'profit-and-loss'}
              />
              <ButtonBack
                onClick={() => {
                  dispatch(resetClicked());
                  dispatch(getData());
                }}
                show={viewId !== 1 && selectedView !== 'table'}
              />
            </Section.Primary>
            {/* Header (Right): Selector */}
            <Section.Secondary>
              <Selector
                selectedDivision={selectedDivision}
                onDateChange={date => {
                  dispatch(dateChanged(date));
                  dispatch(getData());
                }}
                onPeriodChange={period => {
                  dispatch(periodChanged(period));
                }}
              />
            </Section.Secondary>
          </Header>
          {selectedDashboard === 'profit-and-loss' ? (
            <ProfitAndLossDashboard />
          ) : (
            <BalanceSheetDashboard />
          )}
        </Content>
      </Main>
    </div>
  );
}

export default Dashboard;
