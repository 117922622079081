import { useSelector, useDispatch } from 'react-redux';
import RightArrow from '../../../static/svg/treeview-arrow-right.svg';
import AnalyzeIcon from '../../../static/svg/analyze-icon.svg';
import { periodChanged } from '../../../state/reducers/dashboardSlice';
import './style.css';

function AnalyzeButton({ node, show, onAnalyseClick }) {
  const dispatch = useDispatch();
  const { metadata, selectedPeriod } = useSelector(state => state.dashboard);
  const clickHandler = () => {
    let indexLabel;
    metadata.forEach((item, index) => {
      if (item.label === node) {
        indexLabel = index;
      }
    });
    onAnalyseClick(indexLabel);
    if (selectedPeriod === 'rf') {
      dispatch(periodChanged('year'));
    }
  };
  return (
    <div
      onClick={clickHandler}
      className="analyze-btn"
      style={{ opacity: show ? '1' : '0', border: 'none' }}
    >
      <span className="analyze-btn-icon-container">
        <img src={AnalyzeIcon} />
      </span>
      Analyze
    </div>
  );
}

export default AnalyzeButton;
