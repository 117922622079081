// This module contains functions that format data for display in the UI.

// constants
const abbreviatedMetrics = ['cogs', 'ebitda', 'opex'];

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * Returns given number in abbreviated notation.
 * @param {float} value - number to format e.g. 1000
 * @returns {string} - formatted number e.g. 1.0k
 */
export function formatNumberToAmount(value) {
  const absValue = Math.abs(value);
  if (absValue >= 10 ** 3) {
    return `${Math.round(value / 10 ** 3).toLocaleString('en-US')} k €`;
  } else {
    return `${Math.round(value)} €`;
  }
  // } else {
  //   return `${Math.round(value)} €`;
  // }
}

export function getNumberPrefix(number) {
  const absNum = Math.abs(number);
  if (absNum < 10 ** 3) {
    return '';
  } else {
    return 'k';
  }
}

export function getNumberMultiplier(prefix) {
  if (prefix == 'k') {
    return 10 ** 3;
  } else {
    return 1;
  }
}

/**
 * Converts date from 'YYYY-MM-DD' to 'MMM' format.
 * @param {string} date - date to convert e.g. '2023-01-01'
 * @returns {string} - formatted date e.g. 'Jan'
 */
export function formatDateToShortMonth(dateString) {
  const monthIndex = parseInt(dateString.slice(5, 7)) - 1;
  const shortMonthName = monthNames[monthIndex].slice(0, 3);
  return shortMonthName.toUpperCase();
}

/**
 * Converts date from 'YYYY-MM-DD' to 'MMM' format.
 * @param {string} date - date to convert e.g. '2023-01-01'
 * @returns {string} - formatted date e.g. 'Jan 2023'
 */
export function formatDateToShortMonthYear(dateString) {
  const monthIndex = parseInt(dateString.slice(5, 7)) - 1;
  const shortMonthName = monthNames[monthIndex].slice(0, 3);
  const year = parseInt(dateString.slice(0, 4));
  return `${shortMonthName.toUpperCase()} ${year}`;
}

/**
 * Converts date from 'YYYY-MM-DD' to 'YYYY' format.
 * @param {string} dateString - date to convert e.g. '2023-01-01'
 * @returns {string} - formatted date e.g. '2023'
 */
export const formatDateToYear = dateString => {
  return dateString.slice(0, 4);
};

/**
 * Converts date from 'YYYY-MM-DD' to 'YYYY -YYYY' format.
 * @param {string} dateString - date to convert e.g. '2023-01-01'
 * @returns {string} - formatted date e.g. '2023-2025'
 */
export const formatDateToMultiYear = dateString => {
  const start = dateString.slice(0, 4);
  const month = dateString.slice(5, 7);
  const quarter = get_quarter(month);
  const end = parseInt(start) + 2;
  return `${quarter} ${start}, MY `;
};

/**
 * Converts date from 'YYYY-MM-DD' to 'YYQ' format.
 * @param {string} dateString - date to convert e.g. '2023-01-01'
 * @returns {string} - formatted date e.g. '23Q1'
 */
export const formatDateToQuarter = dateString => {
  const month = dateString.slice(5, 7);
  const year = dateString.slice(0, 4);
  const quarter = get_quarter(month);
  return `${year}${quarter}`;
};

/**
 * Converts date from 'YYYY-MM-DD' to 'Month YYYY' format.
 * @param {string} dateString - date to convert e.g. '2023-01-01'
 * @returns {string} - formatted date e.g. 'January 2023'
 */
export function formatDateToMonth(dateString) {
  if (!dateString) {
    return '';
  }
  const monthIndex = parseInt(dateString.slice(5, 7)) - 1;
  const month = monthNames[monthIndex];
  const year = parseInt(dateString.slice(0, 4));
  return `${month} ${year}`;
}

/**
 * TODO: get rid of this function
 * Converts key from data key format to name format.
 * @param {string} dataKey - data key to convert e.g. 'grossProfit
 * @returns {string} - converted data key e.g. 'Gross Profit'
 */
export function formatDataKeyToLabel(dataKey) {
  if (!dataKey) {
    return '';
  }
  if (dataKey == 'nonOpex') {
    return 'Non-OPEX';
  }
  if (abbreviatedMetrics.includes(dataKey)) {
    return dataKey.toUpperCase();
  }
  const capDataKey = dataKey.charAt(0).toUpperCase() + dataKey.slice(1); // capitalize first letter
  const words = capDataKey.split(/(?=[A-Z])/); // split at capital letters
  return words.join(' '); // join with spaces
}

/**
 * Rounds a amount/number to the nearest integer and coverts the  number to a locale string.
 * @param {number} amount - amount to round and convert
 * @param {string} locale - which locale to use e.g. 'en-US'
 * @returns {number} - returns the rounded and converted amount
 */
export const displayNumber = (amount, locale) => {
  let n = Math.round(amount);
  n = n.toLocaleString(locale);
  return n;
};

export const get_quarter = month => {
  if (month == 1 || month == '01') {
    return 'Q1';
  } else if (month == 4 || month == '04') {
    return 'Q2';
  } else if (month == 7 || month == '07') {
    return 'Q3';
  } else if (month == 10 || month == '10') {
    return 'Q4';
  }
};

export const getQuarterIndex = month => {
  if (month === 0) {
    return 0;
  } else if (month === 1) {
    return 0;
  } else if (month === 2) {
    return 0;
  } else if (month === 3) {
    return 1;
  } else if (month === 4) {
    return 1;
  } else if (month === 5) {
    return 1;
  } else if (month === 6) {
    return 2;
  } else if (month === 7) {
    return 2;
  } else if (month === 8) {
    return 2;
  } else if (month === 9) {
    return 3;
  } else if (month === 10) {
    return 3;
  } else if (month === 11) {
    return 3;
  }
};
