import { useSelector, useDispatch } from 'react-redux';
import Arrow from '../Arrow';
import Button from '../Button';
import Tile from '../Tile';
import ViewSelectorView from './ViewSelectorView';
import { viewSelectorViewSelected } from '../../state/reducers/dashboardSlice';
import { menuClicked } from '../../state/reducers/menuSlice';
import ViewIcon from '../../static/svg/graph.svg';
import './style.css';

function ViewSelector({ show }) {
  const dispatch = useDispatch();
  const { views } = useSelector(state => state.dashboard);
  const { showViews } = useSelector(state => state.menu);

  const toggle = () => {
    dispatch(menuClicked('showViews'));
    if (!showViews) {
      dispatch(viewSelectorViewSelected(''));
    }
  };

  const viewsList = views.map((view, index) => (
    <ViewSelectorView
      key={index}
      view={view.view}
      options={view.options}
      show={showViews}
      click={toggle}
    />
  ));

  return (
    <div
      className="view-selector-container"
      style={{ display: show ? 'block' : 'none' }}
    >
      {/* <Tile className="tile-content"> */}
      <Tile className="tile-content-view-fn">
        <Button className="view-selector-button" onClick={toggle}>
          <img src={ViewIcon} />
          Edit View
          {/* <Arrow shape="arrowhead" direction={showViews ? 'up' : 'down'} /> */}
          <Arrow
            shape="arrowhead-black-margin"
            direction={showViews ? 'up' : 'down'}
          />
        </Button>
      </Tile>
      {showViews && <div className="view-selector-views">{viewsList}</div>}
    </div>
  );
}

export default ViewSelector;
