export const DROPDOWNS = [
  {
    label: 'Group by',
    defaultIndex: 4,
    options: ['account', 'ledger', 'ledgerGrouping', 'costCenter', ''],
  },
  {
    label: 'Order by',
    defaultIndex: 2,
    options: ['amountAscending', 'amountDescending', ''],
  },
  {
    label: 'Compare to',
    defaultIndex: 2,
    options: ['previousPeriod', 'budget', ''],
  },
];

export const HEADER_TILE_HEIGHT = 1.2 / 7;
export const TABLE_TILE_HEIGHT = 1 - HEADER_TILE_HEIGHT;
