import { useDispatch, useSelector } from 'react-redux';

import DashboardChart from '../DashboardChart';
import DashboardTable from '../DashboardTable';
import DeepdivePanel from '../DeepdivePanel';
import MetricScore from '../MetricScore';
import Section from '../Layout/Section';
import {
  makeMetricScoresData,
  filterDataForPeriod,
} from '../../views/Dashboard/utils';

import {
  getData,
  analyseClicked,
  groupChanged,
  orderChanged,
  scorecardHovered,
  scorecardClicked,
} from '../../state/reducers/dashboardSlice';

function ProfitAndLossDashboard() {
  const dispatch = useDispatch();
  const {
    isLoading,
    data,
    metadata,
    selectedDate,
    selectedPeriod,
    selectedGroup,
    selectedView,
    viewId,
    highlightedMetric,
    metricKey,
    activeMetrics,
  } = useSelector(state => state.dashboard);

  /**
   * Handles click on 'analyse' button event: updates to metric deepdive view
   * @param {integer} index - index of the scorecard / metric
   */
  const handleAnalyseClick = index => {
    if (selectedView === 'graphs') {
      dispatch(analyseClicked(index));
      dispatch(getData());
    }

    if (selectedView === 'table') {
      dispatch(analyseClicked(index));
      dispatch(getData());
    }
  };

  /**
   * Handles hover on scorecard event: updates the highlighted metric (index)
   * @param {integer} index - index of the scorecard / metric
   */
  const handleMetricScoreHover = index => {
    dispatch(scorecardHovered(index));
  };

  let scorecards, metadataActiveMetrics;
  if (selectedView === 'graphs' && !isLoading) {
    scorecards =
      viewId == 1
        ? makeMetricScoresData(data, metadata, selectedDate, selectedPeriod)
        : [];

    scorecards = scorecards.filter(
      scorecard => !scorecard.label.includes('Budget')
    );
    metadataActiveMetrics = metadata.map((chart, index) => {
      return activeMetrics.includes(index) ? chart : {};
    });
  }

  return (
    <>
      {selectedView === 'graphs' && !isLoading && (
        <>
          {/* Primary Content (Left): Chart */}
          <Section.Primary>
            <DashboardChart
              data={data}
              metadata={metadataActiveMetrics}
              viewId={viewId}
              highlightedMetric={highlightedMetric}
            />
          </Section.Primary>
          {/* Secondary Content (Right): MetricScores */}
          <Section.Secondary>
            {viewId == 1 ? (
              scorecards.map((scorecard, index) => (
                <MetricScore
                  key={index}
                  index={index}
                  height={1 / scorecards.length}
                  isActive={activeMetrics.includes(index)}
                  onHover={handleMetricScoreHover}
                  onClick={() => dispatch(scorecardClicked(index))}
                  onAnalyseClick={handleAnalyseClick}
                  {...scorecard}
                />
              ))
            ) : (
              <DeepdivePanel
                data={filterDataForPeriod(data, selectedDate, selectedPeriod)}
                metadata={metadata}
                metricKey={metricKey}
                activeMetrics={activeMetrics}
                viewId={viewId}
                groupBy={selectedGroup}
                onGroupChange={group => {
                  dispatch(groupChanged(group));
                  dispatch(getData());
                }}
                onOrderChange={order => {
                  dispatch(orderChanged(order));
                  dispatch(getData());
                }}
              />
            )}
          </Section.Secondary>
        </>
      )}
      {selectedView === 'table' && !isLoading && (
        <>
          <DashboardTable
            onAnalyseClick={handleAnalyseClick}
            data={data}
            analysableNodes={metadata
              .filter(graph => graph.type === 'bar')
              .map(graph => graph.label)}
            columnOrder={null}
            styling={true}
          />
        </>
      )}
    </>
  );
}

export default ProfitAndLossDashboard;
