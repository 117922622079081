export const ApiBaseUrl = process.env.REACT_APP_API_ENDPOINT;

// OAuth API paths
export const AuthGooglePath = '/auth/google';
export const AuthMicrosoftPath = '/auth/microsoft';
export const UserInfoPath = '/auth/me';
export const logOutPath = '/auth/logout';

// dashboard API paths
export const ClientPath = '/dashboards/clients';
export const LogosPath = '/dashboards/logos';
export const PeriodsPath = '/dashboards/periods';
export const ProfitAndLossPath = '/dashboards/profit-and-loss';
export const DashboardsPath = '/dashboards';
export const BudgetsPath = '/dashboards/budgets';

// exact API endpoints
export const UpdateDataPath = '/exact/update';

export const COLORS = {
  blueNavy: '#0A2D5C',
  purpleNavy: '#3A497D',
  lightGray: '#D8DBE5',
  gray: '#E5E5E5',
  black: '#1D1E14',
  medblack: 'rgba(29, 30, 20, 0.3)',
};
