import { getNumberMultiplier, getNumberPrefix } from '../../utils/formatters';
import { sum } from '../../utils/math';

export function isAnyFilterSelected(state) {
  return state.selectedGroup || state.selectedOrder || state.selectedCompare;
}

export function makeMetricScoresData(
  data,
  metadata,
  selectedDate,
  selectedPeriod
) {
  // slice data to only include current month or ytd
  const dataSlice = sliceDataForPeriod(data, selectedDate, selectedPeriod);

  // if data or metadata is empty, return array of empty objects
  if (!dataSlice || !metadata || !dataSlice.length) {
    return [{}, {}, {}, {}, {}, {}, {}];
  }
  const keys = metadata.map(chart => chart.label);
  const maxAbsVal = getMaxAbsVal(dataSlice, keys);
  const prefix = getNumberPrefix(maxAbsVal);
  const multiplier = getNumberMultiplier(prefix);

  const scorecards = [];
  for (const chart of metadata.values()) {
    scorecards.push({
      value: (1 / multiplier) * sum(dataSlice.map(item => item[chart.label])),
      prefix: prefix,
      unit: chart.unit,
      color: chart.color,
      type: chart.type,
      label: chart.label,
    });
  }
  return scorecards;
}

export function sliceDataForPeriod(data, date, period) {
  const monthIndex = parseInt(date.slice(5, 7)) - 1;
  // if period is month, slice data to only include current month
  if (period === 'month') {
    return data.slice(monthIndex, monthIndex + 1);
  }
  // if period is ytd, slice data to include all months up to and including current month
  else if (period === 'ytd') {
    return data.slice(0, monthIndex + 1);
  }
  // else return data as is
  else {
    return data;
  }
}

export function filterDataForPeriod(data, date, period) {
  const monthIndex = parseInt(date.slice(5, 7)) - 1;
  // if period is month, filter data to only include current month
  if (period === 'month') {
    return data.filter((_, index) => index == monthIndex);
  }
  // if period is ytd, filter data to include all months up to and including current month
  else if (period === 'ytd') {
    return data.filter((_, index) => index <= monthIndex);
  }
  // else return data as is
  else {
    return data;
  }
}

function getMaxAbsVal(data, keys) {
  const values = keys.map(key => sum(data.map(item => item[key])));
  return Math.max(...values.map(Math.abs));
}

// Extension of Array class to add toggle method
export class List {
  constructor(array) {
    this.array = array;
  }

  add(item) {
    if (!this.array.includes(item)) {
      this.array.push(item);
    }
  }

  remove(item) {
    if (this.array.includes(item)) {
      this.array.splice(this.array.indexOf(item), 1);
    }
  }

  map(fn) {
    return this.array.map(fn);
  }

  includes(item) {
    return this.array.includes(item);
  }

  indexOf(item) {
    return this.array.indexOf(item);
  }

  toggle(item) {
    if (this.array.includes(item)) {
      this.remove(item);
    } else {
      this.add(item);
    }
    this.array.sort();
    return this;
  }
}
