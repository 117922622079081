import { useEffect, useState } from 'react';
import {
  getRootNodes,
  setColumns,
  objectDepth,
  getPathLengths,
  sortObj,
} from './utils/utils';
import Branch from './Branch';
import './style.css';

function TreeView({
  data,
  onAnalyseClick,
  analysableNodes,
  rootNodes,
  columnOrder,
  styling,
}) {
  const [root, setRoot] = useState([]);
  const [lastNode, setLastNode] = useState([]);
  const [branchesPaths, setBranchesPaths] = useState({});

  useEffect(() => {
    setRoot(getRootNodes(data, rootNodes));
    let lnode = getRootNodes(data, rootNodes);
    lnode = lnode[lnode.length - 1][0].name;

    setLastNode([lnode]);
    setBranchesPaths(getPathLengths(data));
  }, []);

  const clicked = node => {
    const branches = [];
    Object.keys(data[node]).forEach(key => {
      if (objectDepth(data[node][key]) > 0) {
        branches.push(data[node][key]);
      }
    });
    let last = branches[branches.length - 1];
    last = Object.keys(last);
    last = last[last.length - 1];
    const path = lastNode;
    if (!path.includes(last)) {
      path.push(last);
      setLastNode(path);
    } else {
      path.pop();
      setLastNode(path);
    }
  };

  const views = root.map((view, index) => (
    <Branch
      key={index}
      data={view}
      tree={index}
      open={true}
      indentation={1}
      last={index === root.length - 1}
      branch={view[0].name}
      rootBranchIndex={index}
      lastNode={lastNode}
      branchesPaths={branchesPaths}
      clicked={clicked}
      onAnalyseClick={onAnalyseClick}
      analysableNodes={analysableNodes}
      columnOrder={columnOrder}
      styling={styling}
    />
  ));

  let columnValues = [];
  let columns = [];
  if (root.length > 0) {
    root[0][0].values.forEach(element => {
      const type = typeof element.value;
      if (type !== 'object') {
        columnValues.push(element);
      }
    });

    if (columnOrder) {
      columnValues = sortObj(columnValues, columnOrder);
    }

    columns = columnValues.map((column, index) => (
      <div
        key={index}
        className={
          column.key.includes(', RF')
            ? 'cell tree-view-column reforecast'
            : 'cell tree-view-column'
        }
        style={{
          borderBottom: '1px solid #EBEDF2',
          borderTopRightRadius:
            index === root[0][0].values.length - 1 ? '8px' : '0px',
        }}
      >
        {column.key}
      </div>
    ));
  }
  return (
    <div className="tree-view-container">
      {root.length > 0 ? (
        <div className="tree-view">
          <div
            className="node-container"
            style={{ gridTemplateColumns: setColumns(root[0][0].values) }}
          >
            <div className="tree-view-empty-cell"></div>
            {columns}
          </div>
        </div>
      ) : null}
      <div className="tree-view-inner">{root ? views : null}</div>
    </div>
  );
}

export default TreeView;
