import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Api from '../../utils/api';

// The default state.
const initialState = {
  divisionsLoading: true,
  isDropdownOpenHeader: false,
  isDropdownOpenFooter: false,
  isDropdownOpen: false,
  showViews: false, // Viewselector open or closed (false)
  dashboardsMenu: false,
  clientsMenu: false,
  username: 'Username',
  selectedClientIndex: 0,
  selectedClient: 1,
  selectedIndex: 0,
  selectedPeriod: 'year',
  selectedPeriodIndex: 0,
  roleId: 0,
  logo: '',
  clients: [],
  divisions: [],
  selectedDivisions: [],
  options: [],
  periodsMenu: {},
  logos: [],
  sideMenuCollapsed: true,
};

export const getUserData = createAsyncThunk('menu/getUserData', async args => {
  const response = await Api.getUser();
  return response.data;
});

export const getClientsData = createAsyncThunk(
  'menu/getClientsData',
  async () => {
    const response = await Api.getClients();
    return response.data;
  }
);

export const getPeriodsData = createAsyncThunk(
  'menu/getPeriodsData',
  async (args, { getState }) => {
    const { dashboard } = getState();
    const response = await Api.getSelectorOptions(dashboard.selectedDivision);
    return response.data;
  }
);

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    divisionsLoadingChanged: (state, { payload }) => {
      state.divisionsLoading = payload;
    },
    dropdownHeaderClicked: (state, { payload }) => {
      state.isDropdownOpenHeader = payload;
    },
    // The reducer to open or close the viewSelector
    viewSelectorClicked: state => {
      state.showViews = !state.showViews;
    },
    menuClicked: (state, { payload }) => {
      const flag = state[payload];
      state.showViews = 'showViews' === payload;
      state.isDropdownOpenHeader = 'isDropdownOpenHeader' === payload;
      state.isDropdownOpenFooter = 'isDropdownOpenFooter' === payload;
      state.isDropdownOpen = 'isDropdownOpen' === payload;
      state.clientsMenu = 'clientsMenu' === payload;
      state.dashboardsMenu = 'dashboardsMenu' === payload;
      state[payload] = !flag;
    },
    dropdownOptionClicked: (state, { payload }) => {
      state.selectedIndex = payload;
      localStorage.setItem('selectedDivisionIndex', payload);
    },
    clientIndexSelected: (state, { payload }) => {
      state.selectedClientIndex = payload;
    },
    clientSelected: (state, { payload }) => {
      state.selectedClient = payload;
      state.selectedDivisions = [];
      localStorage.setItem('selectedClient', payload);
      localStorage.setItem('selectedDivision', '');
      localStorage.setItem('selectedDivisionIndex', '0');
    },
    clientsDataSet: (state, { payload }) => {
      state.clients = payload;
      state.selectedClient = payload[0].id;
    },
    logosDataSet: (state, { payload }) => {
      state.logos = payload;
    },
    logoSelected: (state, { payload }) => {
      state.logo = payload;
    },
    divisionDataSet: (state, { payload }) => {
      state.divisions = payload;
      state.selectedIndex = 0;
    },
    divisionsSelected: (state, { payload }) => {
      state.selectedDivisions = payload;
    },
    periodsDataSet: (state, { payload }) => {
      state.periodsMenu = payload;
      const { defaultIndex, options } = payload[state.selectedPeriod];
      state.options = options;
      state.selectedPeriodIndex = defaultIndex;
    },
    periodSelected: (state, { payload }) => {
      state.selectedPeriod = payload;
    },
    periodIndexSelected: (state, { payload }) => {
      state.selectedPeriodIndex = payload;
    },
    optionsUpdated: (state, { payload }) => {
      state.options = payload;
    },
    dropdownFooterClicked: (state, { payload }) => {
      state.isDropdownOpenFooter = payload;
    },
    sideMenuCollapseClicked: state => {
      state.sideMenuCollapsed = !state.sideMenuCollapsed;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getUserData.fulfilled, (state, { payload }) => {
        const { first_name, role_id } = payload.data;
        state.username = first_name;
        state.roleId = role_id;
      })
      .addCase(getClientsData.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.clients = data;
      })
      .addCase(getPeriodsData.fulfilled, (state, { payload }) => {
        state.periodsMenu = payload.data;
        const { defaultIndex, options } = payload.data[state.selectedPeriod];
        state.options = options;
        state.selectedPeriodIndex = defaultIndex;
      });
  },
});

export const {
  divisionsLoadingChanged,
  dropdownHeaderClicked,
  dropdownOptionClicked,
  clientIndexSelected,
  clientSelected,
  dropdownFooterClicked,
  clientsDataSet,
  divisionDataSet,
  periodsDataSet,
  periodSelected,
  periodIndexSelected,
  optionsUpdated,
  divisionsSelected,
  viewSelectorClicked,
  menuClicked,
  logosDataSet,
  logoSelected,
  sideMenuCollapseClicked,
} = menuSlice.actions;

export default menuSlice.reducer;
