import { useState } from 'react';
import { useSelector } from 'react-redux';
import { InView } from 'react-intersection-observer';
import { setColumns, sortObj } from '../utils/utils';
import { displayNumber } from '../../../utils/formatters';
import Cell from '../Cell/component';
import './style.css';

function Node({
  item,
  values,
  arrow,
  indentation,
  click,
  last,
  branch,
  clicked,
  lastNode,
  onAnalyseClick,
  rootBranchIndex,
  analysableNodes,
  columnOrder,
  styling,
  open,
}) {
  const { symbolLookup, styleLookup, ppSymbol } = useSelector(
    state => state.dashboard
  );
  const [background, setBackground] = useState('#ffffff');
  const [showBtn, setShowBtn] = useState(false);

  if (item === 'Unprocessed Result') {
    styling = true;
  }

  let nodeValues = [];
  values.forEach(element => {
    const type = typeof element.value;
    if (type !== 'object') {
      nodeValues.push(element);
    }
  });

  if (columnOrder) {
    nodeValues = sortObj(nodeValues, columnOrder);
  }

  const checkSymbol = item => {
    if (symbolLookup[item]) {
      return symbolLookup[item];
    } else {
      return null;
    }
  };

  const checkStyle = item => {
    if (styleLookup[item]) {
      return styleLookup[item];
    } else {
      return null;
    }
  };

  const columns = nodeValues.map((value, index) => (
    <div
      className="cell"
      key={value.key}
      style={{
        borderBottom:
          item === lastNode[lastNode.length - 1] ? 'none' : '1px solid #EBEDF2',
      }}
    >
      <div
        className="cell-value"
        style={
          styling && {
            fontWeight:
              checkStyle(item) && indentation === 1
                ? checkStyle(item).weight
                : '300',
            fontStyle: checkStyle(item) ? checkStyle(item).style : 'normal',
          }
        }
      >
        {ppSymbol.includes(item) &&
        (index - nodeValues.length === -3 ||
          index - nodeValues.length === -1) ? (
          // <span>pp</span>
          <span>%</span>
        ) : checkSymbol(item) ? (
          <span>{checkSymbol(item)}</span>
        ) : (
          <span>€</span>
        )}
        <span>{displayNumber(value.value)}</span>
      </div>
    </div>
  ));

  const handleMouseEnter = () => {
    setBackground('#FBFBF6');
    setShowBtn(true);
  };

  const handleMouseLeave = () => {
    setBackground('#ffffff');
    setShowBtn(false);
  };

  return (
    <InView
      as="div"
      className={
        item === 'Liabilities' ? 'node-container liab' : 'node-container'
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        gridTemplateColumns: setColumns(values),
        background: background,
        transition: 'background 0.2s linear',
      }}
    >
      <Cell
        item={item}
        lastNode={lastNode}
        indentation={indentation}
        arrow={arrow}
        analyzebtn={analysableNodes.includes(item)}
        click={click}
        last={last}
        clicked={clicked}
        branch={branch}
        showBtn={showBtn}
        onAnalyseClick={onAnalyseClick}
        rootBranchIndex={rootBranchIndex}
        styling={styling}
        setStyle={checkStyle(item)}
        open={open}
        first={true}
        background={background}
      />
      {columns}
    </InView>
  );
}

export default Node;
