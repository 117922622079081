import ListItem from '../ListItem';

import {
  formatDateToMonth,
  formatNumberToAmount,
  formatDateToQuarter,
} from '../../utils/formatters';
import './style.css';

const CustomTooltip = ({ active, payload, label, period }) => {
  // returns custom tooltip component

  if (!active) {
    return null;
  } else {
    return (
      <div className="tooltip-wrapper">
        <div className="tooltip-label">
          {period === 'mulyears'
            ? formatDateToQuarter(label)
            : formatDateToMonth(label)}
        </div>
        <div className="tooltip-legend-wrapper">
          {payload &&
            payload.map((item, index) => (
              <ListItem
                key={index}
                color={
                  item.name === 'Net Income'
                    ? 'rgba(149, 196, 243, 1)'
                    : item.name === 'EBITDA'
                    ? 'rgba(255, 144, 143, 1)'
                    : item.name === 'Gross Profit'
                    ? 'rgba(249, 219, 123, 1)'
                    : item.color
                }
                label={item.name}
                value={formatNumberToAmount(item.value)}
                className={item.name === 'None' ? 'none-value' : null}
              />
            ))}
        </div>
      </div>
    );
  }
};

export default CustomTooltip;
