import axios from './axiosInstance';
import {
  ApiBaseUrl,
  AuthGooglePath,
  AuthMicrosoftPath,
  DashboardsPath,
  UserInfoPath,
  ClientPath,
  LogosPath,
  UpdateDataPath,
  PeriodsPath,
  BudgetsPath,
  LoggedInPath,
  logOutPath,
} from './constants';

// -------------------------- General --------------------------

function apiGet(path) {
  return axios.get(ApiBaseUrl + path, {
    withCredentials: true,
  });
}

// ------------------------- Login API -------------------------

export function loginGoogle() {
  return apiGet(AuthGooglePath);
}

export function loginMicrosoft() {
  return apiGet(AuthMicrosoftPath);
}

export function loginCallback() {
  // check if there is a query string with 'code' in it
  const queryString = window.location.search;
  if (!queryString.includes('?code=')) {
    window.location = '/login';
  }
  // determine API to call based on query string
  const callbackPath = queryString.includes('googleapis')
    ? AuthGooglePath
    : AuthMicrosoftPath;
  // find code in query string and include in callback URL
  const code = queryString.split('?code=')[1];
  return apiGet(callbackPath + '/callback?code=' + code);
}

// ----------------------- Auth/session API -----------------------
export function logOut() {
  return apiGet(logOutPath);
}
// ----------------------- Dashboard API -----------------------

export function getDashboard(
  balanceType,
  division,
  metric,
  date,
  period,
  group,
  order,
  view,
  budget,
  budgetvar
) {
  const queryParams = {
    division,
    metric,
    date,
    period,
    group,
    order,
    budget,
    budgetvar,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${DashboardsPath}/${view}/${balanceType}?${queryString}`;
  return apiGet(url);
}

export function getScenarios(division) {
  const queryParams = { division };
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${BudgetsPath}?${queryString}`;
  return apiGet(url);
}

export function getSelectorOptions(division) {
  const queryParams = { division };
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${PeriodsPath}?${queryString}`;
  return apiGet(url);
}

export function updateClientData(ew_client_id) {
  const queryParams = { ew_client_id };
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${UpdateDataPath}?${queryString}`;
  return apiGet(url);
}

export function getUser() {
  return apiGet(UserInfoPath);
}

export function getClients() {
  return apiGet(ClientPath);
}

export function getLogos() {
  return apiGet(LogosPath);
}
