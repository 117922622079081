import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  periodIndexSelected,
  periodSelected,
  optionsUpdated,
  menuClicked,
} from '../../state/reducers/menuSlice';
import Arrow from '../Arrow';
import Button from '../Button';
import Tile from '../Tile';
import CalenderIcon from '../../static/svg/calender.svg';

import { dropdownOptions, formatters } from './constants';
import './style.css';

function Selector({ onDateChange, onPeriodChange } = props) {
  const dispatch = useDispatch();
  const { periodsMenu, options, selectedPeriodIndex, isDropdownOpen } =
    useSelector(state => state.menu);
  const { selectedView, selectedPeriod, selectedDashboard } = useSelector(
    state => state.dashboard
  );

  useEffect(() => {}, [selectedPeriod]);

  const changeSelection = increment => {
    dispatch(menuClicked());
    // check if options is empty or new index is out of bounds
    const n = options.length;
    const newIndex = selectedPeriodIndex + increment;
    if (n == 0 || newIndex < 0 || newIndex > n - 1) {
      return;
    }

    dispatch(periodIndexSelected(newIndex));
    onDateChange(options[newIndex]);
  };

  const handleClickPeriodDropdown = () => {
    dispatch(menuClicked('isDropdownOpen'));
  };

  const handleClickPeriodOption = selectedPeriod => {
    const { options, defaultIndex } = periodsMenu[selectedPeriod];
    dispatch(periodIndexSelected(defaultIndex));
    dispatch(periodSelected(selectedPeriod));
    dispatch(optionsUpdated(options));
    onPeriodChange(selectedPeriod);
    onDateChange(options[defaultIndex]);
    dispatch(menuClicked());
  };

  // check if options is empty, if yes, return empty selectedDate

  const selectedDate = options.length > 0 ? options[selectedPeriodIndex] : '';
  const direction = isDropdownOpen ? 'up' : 'down';
  const formatDate = formatters[selectedPeriod];
  return (
    <>
      <Tile className="tile-content-fn-selector">
        <Button
          className="selector-arrow-container-left"
          onClick={() => changeSelection(-1)}
        >
          {/* <Arrow shape="arrowhead" direction={'left'} /> */}
          <Arrow shape="arrowhead-black" direction={'left'} />
        </Button>
        <div className="selector-month-container">
          <img src={CalenderIcon} />
          <div className="selector-month-wrapper">
            {`${formatDate(selectedDate)} `} {selectedPeriod == 'ytd' && 'YTD'}
            {selectedPeriod == 'rf' && 'RF'}
            <span></span>
            <Button onClick={handleClickPeriodDropdown}>
              {/* <Arrow shape="arrowhead" direction={direction} /> */}
              {/* <Arrow shape="arrowhead-black-margin" direction={direction} /> */}
            </Button>
          </div>
        </div>
        <Button
          className="selector-arrow-container-right"
          onClick={() => changeSelection(+1)}
        >
          {/* <Arrow shape="arrowhead" direction={'right'} /> */}
          <Arrow shape="arrowhead-black" direction={'right'} />
        </Button>
      </Tile>
      {isDropdownOpen && (
        <div className="selector-dropdown-container">
          {dropdownOptions.map(option => {
            return (
              <Button
                key={option.key}
                className={
                  (option.key == 'rf' || option.key == 'mulyears') &&
                  selectedDashboard == 'balance-sheet'
                    ? 'selector-dropdown-item-not-active'
                    : 'selector-dropdown-item-active'
                }
                onClick={
                  (option.key == 'rf' || option.key == 'mulyears') &&
                  selectedDashboard == 'balance-sheet'
                    ? null
                    : () => handleClickPeriodOption(option.key)
                }
              >
                {option.label}
              </Button>
            );
          })}
        </div>
      )}
    </>
  );
}

export default Selector;
