import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ArrowIcon from '../../static/svg/arrow.svg';
import ArrowTurIcon from '../../static/svg/arrow-tur.svg';
import ArrowCircularIcon from '../../static/svg/arrow-circular.svg';
import ArrowCircularBlackIcon from '../../static/svg/arrow-circular-black.svg';
import ArrowBlackIcon from '../../static/svg/arrow-black.svg';
import ArrowBlackLightIcon from '../../static/svg/arrow-black-light.svg';
import ArrowWhiteIcon from '../../static/svg/arrow-white.svg';

import './style.css';

class Arrow extends Component {
  static get propTypes() {
    return {
      shape: PropTypes.string,
      direction: PropTypes.string,
    };
  }

  render() {
    // determine direction of arrow ('left', 'up', 'right' or 'down')
    const angle = this.props.direction;
    const size = this.props.size;
    let icon;
    if (this.props.shape === 'arrowhead') {
      icon = ArrowIcon;
    } else if (this.props.shape === 'circular') {
      icon = ArrowCircularIcon;
    } else if (this.props.shape === 'circular-black') {
      icon = ArrowCircularBlackIcon;
    } else if (this.props.shape === 'arrowhead-black') {
      icon = ArrowBlackIcon;
    } else if (this.props.shape === 'arrowhead-black-margin') {
      icon = ArrowBlackIcon;
    } else if (this.props.shape === 'arrowhead-black-light') {
      icon = ArrowBlackLightIcon;
    } else if (this.props.shape === 'arrowhead-white') {
      icon = ArrowWhiteIcon;
    } else {
      icon = ArrowTurIcon;
    }
    return (
      <div className={`arrow-${this.props.shape}-wrapper`}>
        <img
          src={icon}
          className={`arrow-${this.props.shape}-svg ${angle}-arrow ${size}-arrow`}
          alt="arrow button"
        />
      </div>
    );
  }
}

export default Arrow;
