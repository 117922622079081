import React, { useState } from 'react';

import { COLORS } from '../../utils/constants';

import Button from '../Button';
import Tile from '../Tile';
// import Arrow from '../Arrow';
import AnalyzeIcon from '../../static/svg/analyze-icon.svg';

import './style.css';

function MetricScoreAmount({ value, prefix, unit }) {
  return (
    <div className="scorecard-bottomrow-wrapper">
      <div className="scorecard-value-sign">{value < 0 ? '-' : ''}</div>
      <div className="scorecard-value-number">
        {`${Math.abs(Math.round(value)).toLocaleString('en-US')}`}
      </div>
      <div className="scorecard-value-unit">
        {prefix} {unit}
      </div>
    </div>
  );
}

function MetricScoreLegend({ color, label, active }) {
  return (
    <div className="scorecard-bottomrow-wrapper">
      {/* <div
        className="scorecard-legend-color"
        style={{ background: color }}
      ></div> */}
      <div
        className="scorecard-legend-label"
        style={{
          background: active ? color : '#F4F1E9',
          color: active ? '#ffffff' : '#D8D8D8',
        }}
      >
        {label}
      </div>
    </div>
  );
}

function MetricScore(props) {
  const [hovered, setHovered] = useState(false);
  // const textColor = props.isActive ? COLORS.purpleNavy : COLORS.lightGray;
  const textColor = props.isActive ? COLORS.black : COLORS.medblack;
  const iconColor = props.isActive ? props.color : COLORS.lightGray;

  const handleAnalyseClick = event => {
    if (event.target.className.includes('scorecard-button')) {
      props.onAnalyseClick(props.index);
    }
  };

  const handleMetricScoreClick = event => {
    if (!event.target.className.includes('scorecard-button')) {
      props.onClick(props.index);
    }
  };

  const handleMouseEnter = index => {
    setHovered(true);
    props.onHover(index);
  };

  const handleMouseLeave = index => {
    setHovered(false);
    props.onHover(index);
  };

  return (
    <Tile
      background={props.isActive ? '#ffffff' : '#F4F1E9'}
      borderBaseColor="2px solid #F4F1E9"
      className="tile-content-fn-metric-score"
      index={props.index}
      height={props.height}
      color={props.color}
      isActive={props.isActive}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className="scorecard-content"
        style={{
          color: textColor,
          // backgroundColor: props.color,
        }}
        onClick={handleMetricScoreClick}
        role="button"
        onKeyDown={() => {}}
        tabIndex={'0'}
      >
        <div
          className="scorecard-toprow"
          style={{ color: props.isActive ? '#464646' : '#D8D8D8' }}
        >
          <MetricScoreAmount
            value={props.value}
            prefix={props.prefix}
            unit={props.unit}
          />
        </div>
        <div className="scorecard-bottomrow">
          <div className="scorecard-bottomrow-left">
            <MetricScoreLegend
              color={iconColor}
              label={props.label}
              active={props.isActive}
            />
          </div>
          <div className="scorecard-bottomrow-right">
            {hovered && props.isActive && props.type == 'bar' && (
              <Button className="scorecard-button" onClick={handleAnalyseClick}>
                <div className="scorecard-button-content">
                  <span className="scorecard-button-icon-container">
                    <img src={AnalyzeIcon} />
                  </span>
                  <span className="scorecard-button-text">Analyse</span>
                  <span className="scorecard-button-separator"></span>
                  {/* <Arrow
                    shape="arrowhead-white"
                    className="scorecard-button-icon"
                    direction="down"
                    size="small"
                  /> */}
                </div>
              </Button>
            )}
          </div>
        </div>
      </div>
    </Tile>
  );
}

export default MetricScore;
