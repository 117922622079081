import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as API from '../../utils/api';
import Arrow from '../Arrow';
import Button from '../Button';
import NavMenu from '../NavMenu';
import {
  getUserData,
  dropdownOptionClicked,
  clientsDataSet,
  periodsDataSet,
  divisionsSelected,
  divisionDataSet,
  menuClicked,
  getPeriodsData,
  clientIndexSelected,
  logoSelected,
  clientSelected,
} from '../../state/reducers/menuSlice';
import {
  divisionSelected,
  getBudgetScenarios,
  getData,
  isLoadingSet,
} from '../../state/reducers/dashboardSlice';
// import EbitwiseLogo from '../../static/img/ebitwise-logo.png';
// import MenuIcon from '../../static/svg/menu-icon.svg';
import MenuIcon from '../../static/svg/menu-demo.svg';
// import AvatarIcon from '../../static/svg/avatar.svg';
import AvatarIcon from '../../static/svg/avatar-demo.svg';
// import ClientsIcon from '../../static/svg/clients.svg';
import ClientsIcon from '../../static/svg/clients-demo.svg';
import DivisionsIcon from '../../static/svg/divisions.svg';
import FnLogo from '../../static/svg/fn-logo-smaller.svg';

import ConsolidationIcon from '../../static/svg/consolidation.svg';
import { ReactComponent as CheckMark } from '../../static/svg/check.svg';

import { USER_OPTIONS, MENU_OPTIONS, AUTHORIZED_ROLES } from './constants';

import './style.css';
import Checkbox from '../Checkbox';

const NavDropdown = ({ className, options, selectedIndex, onClick, type }) => {
  const dispatch = useDispatch();
  const { selectedDivisions, divisions } = useSelector(state => state.menu);
  const [show, setShow] = useState(false);

  const clientOptions = [...options];
  // if (type !== 'footer') {
  //   clientOptions.push('Consolidated');
  // }

  const handleSubmenuOption = option => {
    const divisionsTemp = [...selectedDivisions];
    if (!divisionsTemp.includes(divisions[option].code)) {
      divisionsTemp.push(divisions[option].code);
    } else {
      const index = divisionsTemp.indexOf(divisions[option].code);
      divisionsTemp.splice(index, 1);
    }
    dispatch(divisionsSelected(divisionsTemp));
  };

  return (
    <>
      <ul className={className}>
        {clientOptions.map((item, index) => (
          <li key={index}>
            <Button onClick={() => onClick(index, item)}>
              <span
                className={
                  index == selectedIndex
                    ? 'nav-header-dropdown-item-text-active'
                    : 'nav-header-dropdown-item-text'
                }
              >
                {item}
              </span>
              <span className="nav-header-dropdown-item-icon">
                {index == selectedIndex && <CheckMark />}
              </span>
            </Button>
          </li>
        ))}
        {/* {type !== 'footer' && (
          <div className="nav-header-dropdown-item-consolidation">
            <Button onClick={() => setShow(!show)}>
              <img
                src={ConsolidationIcon}
                className="nav-header-dropdown-item-consolidation-icon"
                alt="consolidation icon"
              />
              <span className="nav-header-dropdown-item-text">
                Select Consolidation Entities
              </span>
            </Button>
            {show && (
              <ul className="nav-header-dropdown-consolidation-sub-menu">
                {options.map((option, index) => (
                  <li
                    key={index}
                    className="nav-header-dropdown-consolidation-sub-menu-option"
                  >
                    <Button key={index} onClick={null}>
                      <Checkbox
                        item={option}
                        onChange={() => handleSubmenuOption(index)}
                        checked={selectedDivisions.includes(
                          divisions[index].code
                        )}
                      />
                    </Button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )} */}
      </ul>
    </>
  );
};

const Nav = () => {
  const dispatch = useDispatch();
  const {
    clients,
    divisions,
    selectedDivisions,
    username,
    selectedClientIndex,
    selectedIndex,
    isDropdownOpenHeader,
    isDropdownOpenFooter,
    roleId,
    logo,
    sideMenuCollapsed,
  } = useSelector(state => state.menu);
  const { selectedDivision } = useSelector(state => state.dashboard);

  useEffect(() => {
    dispatch(getUserData());
    getAlldata();
  }, []);

  const getAlldata = async () => {
    const clients = await API.getClients();
    dispatch(clientsDataSet(clients.data.data));

    const storedClientIndex = clients.data.data.findIndex(item => {
      return item.id === parseInt(localStorage.getItem('selectedClient'));
    });

    let divisions;
    if (storedClientIndex >= 0) {
      divisions = clients.data.data[storedClientIndex].divisions;
      dispatch(
        clientSelected(parseInt(localStorage.getItem('selectedClient')))
      );
      dispatch(clientIndexSelected(storedClientIndex));
      dispatch(logoSelected(clients.data.data[storedClientIndex].logo_url));
      dispatch(divisionDataSet(divisions));
      const storedDivision = localStorage.getItem('selectedDivision');
      if (storedDivision) {
        dispatch(divisionSelected(storedDivision));
        dispatch(
          dropdownOptionClicked(
            parseInt(localStorage.getItem('selectedDivisionIndex'))
          )
        );
        dispatch(divisionsSelected(storedDivision.split(',')));
      } else {
        if (divisions[0]) {
          dispatch(divisionSelected(divisions[0].code));
        } else {
          dispatch(isLoadingSet(false));
        }
      }
    } else {
      dispatch(logoSelected(clients.data.data[0].logo_url));
      divisions = clients.data.data[0].divisions;

      localStorage.setItem('selectedClient', clients.data.data[0].id);
      localStorage.setItem('selectedDivisionIndex', '0');
      dispatch(divisionDataSet(divisions));
      dispatch(divisionSelected(divisions[1].code));
    }
    const periods = await API.getSelectorOptions(divisions[0].code);
    dispatch(periodsDataSet(periods.data.data));
    dispatch(getBudgetScenarios());
    dispatch(getData());
  };

  const handleClickDropdownHeader = () => {
    dispatch(menuClicked('isDropdownOpenHeader'));
  };

  const handleSelectDropdownOption = (index, item) => {
    if (item === 'Consolidated') {
      dispatch(dropdownOptionClicked(index));
      if (selectedDivisions.length !== 0) {
        let tempDivisions = '';
        selectedDivisions.forEach(
          item => (tempDivisions = tempDivisions + `${item},`)
        );
        dispatch(divisionSelected(tempDivisions.slice(0, -1)));
        dispatch(getPeriodsData());
        dispatch(getBudgetScenarios());
        dispatch(getData());
        dispatch(menuClicked());
      } else {
        return;
      }
    } else if (selectedDivision === divisions[index].code) {
      return;
    } else {
      dispatch(dropdownOptionClicked(index));
      dispatch(divisionSelected(divisions[index].code));
      dispatch(getPeriodsData());
      dispatch(getBudgetScenarios());
      dispatch(getData());
      dispatch(menuClicked());
    }
  };

  const handleClickDropdownFooter = () => {
    dispatch(menuClicked('isDropdownOpenFooter'));
  };

  let clientName;
  if (localStorage.getItem('selectedClient')) {
    clientName = clients.find(item => {
      return item.id === parseInt(localStorage.getItem('selectedClient'));
    });
    if (clientName) {
      clientName = clientName.name;
    } else {
      clientName = '';
    }
  } else {
    clientName = clients.length > 0 ? clients[selectedClientIndex].name : '';
  }

  return (
    <nav>
      {/* Header */}
      <header>
        <div className="nav-header-icon">
          <div className={'nav-header-icon-container'}>
            {<img src={FnLogo} className="nav-header-icon-image" />}
          </div>
        </div>
        <div
          className="nav-header-content"
          style={{
            opacity: sideMenuCollapsed ? '0' : '1',
            transition: 'opacity 0.4s ease-in-out',
          }}
        >
          {/* <div className="nav-header-title">{clientName}</div>
          <div className="nav-header-subtitle">
            <span>Powered by FinSights</span>
            <img src={EbitwiseLogo} alt="logo" height={17} />
          </div> */}
        </div>
        {/* <div
          className="nav-header-button"
          style={{
            right: sideMenuCollapsed ? '193px' : '4px',
            transition: 'all 0.7s ease-in-out',
          }}
        >
          <Button
            className="nav-header-button-container"
            onClick={handleClickDropdownHeader}
          >
            <Arrow
              // shape="arrowhead"
              shape="arrowhead-black"
              direction={isDropdownOpenHeader ? 'up' : 'down'}
            />
          </Button>
        </div> */}
      </header>
      {isDropdownOpenHeader && (
        <NavDropdown
          className={
            sideMenuCollapsed
              ? 'nav-header-dropdown-collapsed'
              : 'nav-header-dropdown'
          }
          options={divisions.map(division => division.description)}
          selectedIndex={selectedIndex}
          // onClick={handleSelectDropdownOption}
        />
      )}

      {/* Navigation Menu */}
      {/* {AUTHORIZED_ROLES.includes(roleId) && (
        <div className="nav-clients-menu-container">
          <NavMenu
            title="Clients"
            menuItems={clients}
            icon={ClientsIcon}
            background={'gray'}
            color={'purplenavy'}
            check={true}
          />
        </div>
      )} */}
      {/* <div className="nav-divisions-menu">
        <span
          className="nav-divisions-label-icon"
          // onClick={handleClickDropdownHeader}
        >
          <img src={DivisionsIcon} />
        </span>
      </div> */}

      {/* <NavMenu
        title="Dashboards"
        menuItems={MENU_OPTIONS}
        icon={MenuIcon}
        background={'lightturquoise'}
        color={'mediumturquoise'}
      /> */}

      {/* Footer */}
      <footer>
        <div className="nav-footer-icon" onClick={handleClickDropdownFooter}>
          <div className="nav-footer-icon-container">
            <img src={AvatarIcon} />
          </div>
        </div>
        {/* <div
          className="nav-footer-content"
          style={{
            opacity: sideMenuCollapsed ? '0' : '1',
            transition: 'opacity 0.4s ease-in-out',
          }}
        >
          <div className="nav-footer-text">{username}</div>
        </div> */}
        {/* <div
          className="nav-footer-button"
          style={{
            right: sideMenuCollapsed ? '185px' : '0px',
            transition: 'all 0.7s ease-in-out',
          }}
        >
          <Button
            className="nav-header-button-container"
            onClick={handleClickDropdownFooter}
          >
            <Arrow
              shape="arrowhead"
              direction={isDropdownOpenFooter ? 'up' : 'down'}
            />
          </Button>
        </div> */}
      </footer>
      {isDropdownOpenFooter && (
        <NavDropdown
          className={
            sideMenuCollapsed
              ? 'nav-footer-dropdown-collapsed'
              : 'nav-footer-dropdown'
          }
          options={USER_OPTIONS.map(user => user.name)}
          onClick={async () => {
            handleClickDropdownFooter();
            dispatch(isLoadingSet(true));
            await API.logOut();
            window.location = '/login';
          }}
          type="footer"
        />
      )}
    </nav>
  );
};

export default Nav;
