import { ResponsiveContainer } from 'recharts';
import Tile from '../Tile';
import TreeView from '../TreeView';
import './style.css';

function DashboardTable({
  onAnalyseClick,
  data,
  analysableNodes,
  rootNodes,
  columnOrder,
  styling,
}) {
  return (
    <Tile className="tile-content-fn-table">
      <div className="dashboard-table-container">
        <div className="dashboard-table-container-inner">
          <ResponsiveContainer width="100%" height="100%">
            <TreeView
              data={data}
              onAnalyseClick={onAnalyseClick}
              analysableNodes={analysableNodes}
              rootNodes={rootNodes}
              columnOrder={columnOrder}
              styling={styling}
            />
          </ResponsiveContainer>
        </div>
      </div>
    </Tile>
  );
}

export default DashboardTable;
