import { useSelector } from 'react-redux';
import Tile from '../Tile';
import Dropdown from '../Dropdown';
import ListItem from '../ListItem';

import {
  getNumberPrefix,
  getNumberMultiplier,
  formatNumberToAmount,
} from '../../utils/formatters';
import { sum } from '../../utils/math';

import { DROPDOWNS, HEADER_TILE_HEIGHT, TABLE_TILE_HEIGHT } from './constants';

import './style.css';

const DeepdivePanel = ({
  data,
  metadata,
  metricKey,
  activeMetrics,
  viewId,
  groupBy,
  onGroupChange,
  onOrderChange,
  // onCompareChange,
}) => {
  // get metric keys for active metrics indices
  const { selectedDashboard } = useSelector(state => state.dashboard);

  const metricKeys =
    metadata.length > 0
      ? activeMetrics.map(index => metadata[index].label)
      : [];
  // get year total for active metrics
  const yearTotal = sum(
    data.map(item => sum(metricKeys.map(key => item[key])))
  );
  const prefix = getNumberPrefix(yearTotal);
  const multiplier = getNumberMultiplier(prefix);
  return (
    <>
      {/* Top Tile */}
      <Tile
        height={HEADER_TILE_HEIGHT}
        className="tile-content-deepdive-header"
      >
        <div className="deepdive-panel-header">
          {selectedDashboard === 'balance-sheet' ? (
            <div className="deepdive-panel-header-amount">
              {Math.round(
                (1 / multiplier) * data[data.length - 1][metricKey]
              ).toLocaleString('en-US')}
              {prefix} €
            </div>
          ) : (
            <div className="deepdive-panel-header-amount">
              {Math.round((1 / multiplier) * yearTotal).toLocaleString('en-US')}{' '}
              {prefix} €
            </div>
          )}
          <div className="deepdive-panel-header-title">{metricKey}</div>
        </div>
      </Tile>
      {/* Bottom Tile */}
      <Tile height={TABLE_TILE_HEIGHT} className="tile-content-deepdive">
        <div
          className="deepdive-panel-content"
          style={{
            position: 'relative',
            zIndex: 100,
            backgroundColor: '#fffff',
            height: groupBy ? '35%' : '20%',
            display: 'none',
          }}
        >
          {/* <Dropdown key={0} {...DROPDOWNS[0]} onChange={onGroupChange} />
          {groupBy && (
            <Dropdown key={1} {...DROPDOWNS[1]} onChange={onOrderChange} />
          )} */}
        </div>
        {viewId !== 1 && data.length > 0 && (
          <div className="deepdive-panel-content" style={{ height: '65%' }}>
            <div className="deepdive-panel-table-wrapper">
              <div className="deepdive-panel-table">
                {viewId === 2 && (
                  <div className="deepdive-panel-table-list-item-wrapper">
                    <ListItem
                      color={'gray'}
                      label={'None'}
                      value={
                        selectedDashboard === 'balance-sheet'
                          ? formatNumberToAmount(
                              data[data.length - 1][metricKey]
                            )
                          : formatNumberToAmount(yearTotal)
                      }
                    />
                  </div>
                )}

                {viewId === 3 &&
                  metadata.map((chart, index) => (
                    <div
                      key={index}
                      className="deepdive-panel-table-list-item-wrapper"
                    >
                      <ListItem
                        color={chart.color}
                        label={chart.label}
                        className={chart.label === 'None' ? 'none-value' : null}
                        value={formatNumberToAmount(
                          sum(data.map(item => item[chart.label]))
                        )}
                      />
                    </div>
                  ))}

                {viewId === 3 && metadata.length === 0 && (
                  <div className="deepdive-panel-table-list-item-wrapper">
                    <ListItem
                      color={'gray'}
                      label={'None'}
                      value={formatNumberToAmount(yearTotal)}
                    />
                  </div>
                )}
              </div>
              <div className="deepdive-panel-table-sum-wrapper">
                {selectedDashboard === 'balance-sheet' ? (
                  <div className="deepdive-panel-table-sum">
                    {Math.round(
                      (1 / multiplier) * data[data.length - 1][metricKey]
                    ).toLocaleString('en-US')}
                    {prefix} €
                  </div>
                ) : (
                  <div className="deepdive-panel-table-sum">
                    {Math.round((1 / multiplier) * yearTotal).toLocaleString(
                      'en-US'
                    )}{' '}
                    {prefix} €
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Tile>
    </>
  );
};

export default DeepdivePanel;
