import { formatNumberToAmount } from '../../utils/formatters';

import { COLORS } from '../../utils/constants';

export const COMPOSED_CHART_PARAMETERS = {
  margin: { top: 10, right: 40, left: 10, bottom: 10 },
  barCategoryGap: 0,
  barGap: 10,
  stackOffset: 'sign',
};

export const X_AXIS_PARAMETERS = {
  dataKey: 'month',
  tick: { fill: '#1b231580' },
  tickSize: 0,
  style: {
    fontFamily: 'DM Sans',
    fontSize: '12px',
    fontWeight: '400',
    color: '#1B2315',
  },
  axisLine: { stroke: COLORS.lightGray },
  tickMargin: 10,
};

export const Y_AXIS_PARAMETERS = {
  style: {
    fontFamily: 'DM Sans',
    fontSize: '12px',
    fontWeight: '400',
    color: '#1B2315',
  },
  stroke: COLORS.lightGray,
  tick: { fill: '#1b231580' },
  tickFormatter: formatNumberToAmount,
};

export const TOOLTIP_PARAMETERS = {
  cursor: false,
  wrapperStyle: { outline: 'none' },
};

export const REFERENCE_LINE_PARAMETERS = {
  stroke: COLORS.lightGray,
  y: 0,
};

export const LINE_PARAMETERS = {
  dot: false,
  // activeDot: false,
  strokeWidth: 2,
  isAnimationActive: false,
};

export const LINE_PARAMETERS_BUDGET = {
  dot: false,
  // activeDot: false,
  strokeWidth: 3,
  isAnimationActive: false,
};
