import Arrow from '../../Arrow';
import Tile from '../../Tile';
import Button from '../component';

import './style.css';

function ButtonBack({ onClick, show }) {
  return (
    <div
      className="button-back-container"
      style={{
        opacity: show ? '1' : '0',
        visibility: show ? 'visible' : 'hidden',
      }}
    >
      <Tile className="tile-button-back">
        <Button className="button-back" onClick={onClick}>
          <Arrow shape="arrowhead" />
          Go Back
        </Button>
      </Tile>
    </div>
  );
}

export default ButtonBack;
