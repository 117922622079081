import { useSelector } from 'react-redux';
import './style.css';
import CollapseButton from '../CollapseButton/component';

export const Header = ({ children }) => <header>{children}</header>;

export const Title = ({ children }) => {
  return <div className="title">{children}</div>;
};

export const Main = ({ children }) => {
  const { sideMenuCollapsed } = useSelector(state => state.menu);
  return (
    <main
      style={{
        left: sideMenuCollapsed ? '110px' : '312px',
        width: sideMenuCollapsed ? 'calc(100% - 110px)' : 'calc(100% - 312px)',
        transition: 'all 0.7s ease-in-out',
      }}
    >
      {/* <div
        style={{
          position: 'absolute',
          left: '-12px',
          top: '90px',
        }}
      >
        <CollapseButton />
      </div> */}
      {children}
    </main>
  );
};

export const Content = ({ children }) => (
  <section className="content">{children}</section>
);
