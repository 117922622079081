import React from 'react';
import { useSelector } from 'react-redux';
import '../style.css';

export const Primary = ({ children }) => (
  <section className="primary">{children}</section>
);

export const Secondary = ({ children }) => {
  const { sideMenuCollapsed } = useSelector(state => state.menu);
  return (
    <section
      className="secondary"
      style={{
        width: sideMenuCollapsed ? '25%' : '25%',
        transition: 'all 0.7s ease-in-out',
      }}
    >
      {children}
    </section>
  );
};
